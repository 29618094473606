import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route render={(props) => <AdminLayout {...props} />} />
        </Switch>
    </BrowserRouter>,
  document.getElementById('root')
);
